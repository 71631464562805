.binary-heading {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;
    height: 30em;
    background-color: black;
  }
  
  .binary-heading .heading-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
  }

  .binary-heading .heading-image {
    width: auto;
    height: 100%;
    position: relative;
  }

  .binary-heading  .heading-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: .3;
  }

  .binary-heading h1 {
    font-size: 2.5em;
    margin: .5em 0;
    text-align: center;
  }
  
  .binary-heading p a {
    color: white;
  }
  
    
  .tree-svg {
    width: 60%;
    margin: 1em auto;
  }

  pre {line-height: 1.8em}


  @media screen and (min-width: 600px) {

    .binary-heading .heading-image {
      width: auto;
      height: 100%;
      position: static;
    }

    .binary-heading .heading-text {
      width: auto;
      position: static;
    }
  
    .binary-heading  .heading-image img {
      opacity: 1;
    }

    .binary-main { 
      width: 80%;
    }
  }

  @media screen and (min-width: 1000px) {
    .binary-main { 
      width: 65%;
    }
  }
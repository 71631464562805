.header {
  width: 100%;
  height: 30em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
}

.photo {
    overflow: hidden;
    width: 7em;
    height: 7em;
    border-radius: 50%;
    margin: 1.5em 0 1em;
  }
  
  .photo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .main { 
    width: 100%;
    padding: 1.5em;
    margin: 0 auto;
    line-height: 2em;
  }

  
  @media screen and (min-width: 600px) {
    .main { 
      width: 80%;
    }
  }

  @media screen and (min-width: 1000px) {
    .main {
      width: 65%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
  }    
  }

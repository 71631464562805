.blog-heading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 28em;
    color: white;
    background-color: rgb(0, 0, 0);
}

.blog-circle {
    width: 100%; 
    height: 100%;
    background-color: rgba(0, 0, 0, 0.516);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2em 1em;
} 

.avatar {
    width: 12em;
    border-radius: 50%;
    margin: 1em 0;
}

.blog-circle h1 {
    text-align: center;
    font-weight: 800;
}

.blog-circle h2 {
    text-align: center;
    font-weight: 800;
}

.blog-circle h4 {
    font-weight: 300;
    margin: 1em 0;
}

.contacts img {
    width: 3em;
    height: 3em;
    margin: .5em;
}

.card .img {
    width: 100%;
    height: 15em;
    overflow: hidden;
}

.card {
    transition: 500ms ease-out;
}

.card:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    transform: translateY(-1.5em);
}

.card .arrow:hover {
    transform: scale(1.1);
}

.bubble-heading {
  color: white;
  background-image: url("../public/images/bubbles.jpeg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: center;
  width: 100%;
  height: 30em;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.header-text {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 1em;
}

.bubble-heading h1 {
  font-size: 2.5em;
  text-align: center;
}

.bubble-heading p a {
  color: white;
}

pre {line-height: 1.8em}



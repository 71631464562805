.queue-heading {
    background-color: black;
    background-image: url('../public/images/ice-cream.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
  
  .overlay {
    background-color: rgba(48, 10, 10, 0.237);
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top:0;
    left:0;
  }

  .queue-heading .heading-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 95%;
    height: auto;
    margin: 0 auto;
    text-align: center;
    color: white;
  }


  .queue-heading  .heading-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .queue-heading h1 {
    font-size: 2.5em;
    margin: 1em 0 .5em;
    font-weight: 600;
  }
  
  .queue-heading .heading-text p a {
    color: white;
  }
   
  .main .graph {
    display: flex;
    justify-content: center;
    padding: 2em;
  }

  .bullet {
    width: .5em;
    height: .5em;
    background-color: red;
    margin: .5em;
  }

.dashboard {
  padding: 2em 1em;
  justify-content: space-between;
  align-items: center;
  font-size: .9em;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
}


  .screen {
    margin: .5em;
    width: 90%;
    background-color: yellow;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-top: 2px rgb(65, 65, 65) solid;
    border-bottom: 2px rgb(65, 65, 65) solid;
  }

  .screen button {
    font-size: 1em;
  }

  .dashboard .board {
    width: 90%;
    display: flex; 
    flex-direction: column;  
  }

  .dashboard .illustration {
    width: 50%;
    height: 100%;
    overflow: hidden;
    margin: 0 auto;
  }

  .dashboard .illustration img {
    width: 100%;
    height: 100%;
  }

  pre {line-height: 1.8em}


  @media screen and (min-width: 600px) {
    .queue-main { 
      width: 80%;
    }

    .dashboard {
      width: 85%;
    }
  }

  @media screen and (min-width: 1000px) {
    .queue-main { 
      width: 65%;
    }

    .queue-heading .heading-text {
      width: 65%;
    }

    .dashboard {
      width: 100%;
    }

    .dashboard .board {
      width: 90%;
      display: flex; 
      flex-direction: row;  
    }
  }
